import { toPng } from "html-to-image";

export const sendDataToBackend = async (
  id: string,
  fileName: string,  
  payloadKey: string,
  reqURL: string,
  reqMethod: string
) => {
  try {
    // await new Promise(resolve => setTimeout(resolve, 100));
    const element = document.getElementById(id);
    const imageData = await convertHtmlToImage(element);
    const formData = new FormData();
    formData.append(payloadKey, dataURLtoFile(imageData, fileName));
    const response = await fetch(reqURL, {
      method: reqMethod,
      body: formData,
    });
    if (!response.ok) {
      throw new Error("Failed to upload image");
    }
    const responseData = await response.json();
    return await responseData;
  } catch (error) {
    console.error("Error sending data to backend:", error);
  }
};
const convertHtmlToImage = async (htmlContent: any) => {
  try {
    const dataUrl = await toPng(htmlContent);
    return dataUrl;
  } catch (error) {
    console.error("Error converting HTML to image:", error);
    throw error;
  }
};
const dataURLtoFile = (image: any, filename: string) => {
  const arr = image.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
