import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  GroupLevel,
  FilterData,
  SelectedFilter,
  SelectedGroup,
} from "../Interface";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useApi } from "../../../../services/HttpService";
import { useParams } from "react-router-dom";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { ItemCard } from "../../../../components/ui/ItemCard";
import {
  BaseInput,
  InputAdornment,
  IconButton,
} from "../../../../components/ui/BaseInput";

interface FilterGroupProps {
  groupLevel: SelectedGroup;
  canEdit: boolean;
  updateSelectedFilters: (
    selected_index: number,
    id: string,
    filter: FilterData
  ) => void;
  updateDisaplyName: (
    id: number,
    tableName: string,
    displayName: string
  ) => void;
}

export const FilterGroup: React.FC<FilterGroupProps> = ({
  groupLevel,
  canEdit,
  updateSelectedFilters,
  updateDisaplyName,
}) => {
  const { uid } = useParams();
  const { selected_index, group } = groupLevel;
  const { id, header, tooltip, data } = group;
  const [values, setValues] = React.useState<FilterData[]>([]);
  const { api, response, isLoading, error } = useApi();
  const [editing, setEditing] = React.useState(-1);
  const [displayName, setDisplayName] = React.useState("");
  const theme = useTheme();

  React.useEffect(() => {
    setValues(data);
    setEditing(-1);
    setDisplayName("");
  }, [data]);

  React.useEffect(() => {
    if (editing > -1) {
      updateDisaplyName(data[editing].id, id, displayName);
      setEditing(-1);
      setDisplayName("");
    }
  }, [response]);

  const handleListItemClick = (
    index: number,
    id: string,
    value: FilterData
  ) => {
    updateSelectedFilters(index, id, value);
  };

  const handleEdit = (index: number) => {
    if (index === editing) {
      setEditing(-1);
      setDisplayName("");
    } else {
      setEditing(index);
      setDisplayName(data[index].display_name);
    }
  };

  const handleSaveDisplayName = () => {
    let params = {
      uid: uid,
      id: data[editing].id,
      table_name: id,
      display_name: displayName,
    };

    api("/updateGlDisplayname", "POST", params);
  };

  const handleCancleDisplayName = () => {
    setEditing(-1);
    setDisplayName("");
  };

  const handleMouseDownDissable = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <ItemCard>
        {groupLevel && (
          <>
            <Box
              sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
            >
              <h2 style={{ margin: 0 }}>{header}</h2>
              {tooltip.length > 0 && (
                <Tooltip title={tooltip}>
                  <InfoOutlinedIcon
                    sx={{
                      ml: 1,
                      color: "#0093ff",
                      fontSize: 28,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ width: "100%", height: 435, overflow: "auto" }}>
              {data.map((value: FilterData, index: number) => {
                return (
                  <ListItem
                    key={value.id}
                    component="div"
                    sx={{
                      mb: 0.5,
                      backgroundColor:
                        selected_index === index
                          ? `${theme.palette.primary.main}`
                          : theme.palette.mode === "dark"
                          ? "black"
                          : "white",
                      color: selected_index === index ? "#fff" : "",
                    }}
                    disablePadding
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <ListItemButton
                        selected={selected_index === index}
                        onClick={() => handleListItemClick(index, id, value)}
                      >
                        {editing === index ? (
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <b style={{ width: "45px" }}>Navn:</b>
                              <ListItemText primary={value.name} />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <b style={{ width: "45px" }}>Nytt:</b>
                              <BaseInput
                                id="edit-display-name"
                                type="text"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                disabled={isLoading}
                                endAdornment={
                                  <InputAdornment>
                                    {isLoading ? (
                                      <CircularProgress
                                        color="info"
                                        size={24}
                                      />
                                    ) : (
                                      <>
                                        <IconButton
                                          size="small"
                                          aria-label="save display name"
                                          onClick={handleSaveDisplayName}
                                          onMouseDown={handleMouseDownDissable}
                                        >
                                          <Tooltip title="Lagre">
                                            <CheckIcon
                                              sx={{ color: "green" }}
                                            />
                                          </Tooltip>
                                        </IconButton>
                                        <IconButton
                                          size="small"
                                          aria-label="cancle display name"
                                          onClick={handleCancleDisplayName}
                                          onMouseDown={handleMouseDownDissable}
                                        >
                                          <Tooltip title="Avbryt">
                                            <CloseIcon
                                              sx={{ color: "red" }}
                                              fontSize="small"
                                            />
                                          </Tooltip>
                                        </IconButton>
                                      </>
                                    )}
                                  </InputAdornment>
                                }
                              />
                            </Box>
                          </Box>
                        ) : (
                          <ListItemText primary={value.display_name} />
                        )}
                      </ListItemButton>
                      {canEdit && editing !== index && (
                        <Box
                          sx={{
                            padding: "10px",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEdit(index)}
                        >
                          <Tooltip title="Rediger">
                            <EditIcon />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                );
              })}
            </Box>
          </>
        )}
      </ItemCard>
    </>
  );
};
