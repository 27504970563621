import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import global_en from "../translations/en/global_en.json";
import global_nor from "../translations/nor/global_nor.json";

i18next.use(initReactI18next).init({
  fallbackLng: "nor",
  debug: true,
  resources: {
    en: {
      LoginPage: global_en.LoginPage,
      HeaderPage: global_en.HeaderPage,
      SideBarPage: global_en.SideBarPage,
      SettingsPage: global_en.SettingsPage,
      DashboardPage: global_en.DashboardPage,
      SearchFilterViewPage: global_en.SearchFilterViewPage,
      FilterComponentPage: global_en.FilterComponentPage,
      NewsletterCardPage: global_en.NewsletterCardPage,
      NewsletterListPage: global_en.NewsletterListPage,
      PosterCardPage: global_en.PosterCardPage,
      PosterListPage: global_en.PosterListPage,
      TemplateCardPage: global_en.TemplateCardPage,
      TemplateListPage: global_en.TemplateListPage,
      MultiSelectPage: global_en.MultiSelectPage,
      DownloadPage: global_en.DownloadPage,
      RolesPage: global_en.RolesPage,
      RolesTablePage: global_en.RolesTablePage,
      CreateUpdateRolesPage: global_en.CreateUpdateRolesPage,
      UserManagementPage: global_en.UserManagementPage,
      UserTablePage: global_en.UserTablePage,
      CreateUpdateUserPage: global_en.CreateUpdateUserPage,
    },
    nor: {
      LoginPage: global_nor.LoginPage,
      HeaderPage: global_nor.HeaderPage,
      SideBarPage: global_nor.SideBarPage,
      SettingsPage: global_nor.SettingsPage,
      DashboardPage: global_nor.DashboardPage,
      SearchFilterViewPage: global_nor.SearchFilterViewPage,
      FilterComponentPage: global_nor.FilterComponentPage,
      NewsletterCardPage: global_nor.NewsletterCardPage,
      NewsletterListPage: global_nor.NewsletterListPage,
      PosterCardPage: global_nor.PosterCardPage,
      PosterListPage: global_nor.PosterListPage,
      TemplateCardPage: global_nor.TemplateCardPage,
      TemplateListPage: global_nor.TemplateListPage,
      MultiSelectPage: global_nor.MultiSelectPage,
      DownloadPage: global_nor.DownloadPage,
      RolesPage: global_nor.RolesPage,
      RolesTablePage: global_nor.RolesTablePage,
      CreateUpdateRolesPage: global_nor.CreateUpdateRolesPage,
      UserManagementPage: global_nor.UserManagementPage,
      UserTablePage: global_nor.UserTablePage,
      CreateUpdateUserPage: global_nor.CreateUpdateUserPage,
    },
  },
});
export default i18next;
