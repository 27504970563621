import {
  registerIcon,
  registerWidget,
  registerWidgetStyleOption,
} from "dmeditor";
import definition from "./definition";
import { LayoutFixedPage } from "./LayoutFixedPage";

export default () => {
  registerIcon({
    name: "parat-logo",
    component: () => <img width={20} src="/images/parat_logo.png" />,
  });
  registerWidgetStyleOption("parat-layout-fixed-page", [
    {
      name: "Default",
      identifier: "default",
      cssStyle: `
        
      `,
    },
  ]);
  registerWidget(definition, { render: LayoutFixedPage });
};
