import { authStore } from "../App";
import localStorageService, { LocalStorageKeys } from "./LocalStorageService";
import { useApi } from "./HttpService";

export const AuthService = () => {
  const { api } = useApi();

  const login = async (tokens: any): Promise<boolean> => {
    localStorageService.setItem(
      LocalStorageKeys.REFRESH_TOKEN,
      tokens.refreshToken
    );
    localStorageService.setItem(LocalStorageKeys.TOKEN, tokens.idToken);

    return true;
  };

  const logout = async (): Promise<boolean> => {
    let refreshToken = localStorageService.getItem(
      LocalStorageKeys.REFRESH_TOKEN
    );
    let token = localStorageService.getItem(LocalStorageKeys.TOKEN);
    if (token) {
      /** @todo: revoke token */
      localStorageService.removeItem(LocalStorageKeys.TOKEN);
    }
    if (refreshToken) {
      /** @todo: revoke refreshToken */
      localStorageService.removeItem(LocalStorageKeys.REFRESH_TOKEN);
    }
    authStore.setState({ isLoggedin: false, userRoles: [], userName: "" });
    return true;
  };

  const authenticate = async (): Promise<boolean> => {
    let isAuthenticated = false;
    let refreshToken = localStorageService.getItem(
      LocalStorageKeys.REFRESH_TOKEN
    );
    let token = localStorageService.getItem(LocalStorageKeys.TOKEN);

    if (!token || !refreshToken) {
      return isAuthenticated;
    }

    await api("/contacts/me").then((res) => {
      console.log("authenticate response:", res);
      if (res) {
        authStore.setState({
          isLoggedin: true,
          userRoles: res.roles,
          userName: res.contact.first_name,
        });
        isAuthenticated = true;
      }
    });

    // return localStorage.getItem('refreshToken') !== null && localStorage.getItem('refreshToken') !== undefined;
    // validate token, get user info

    return isAuthenticated;
  };

  return { login, logout, authenticate };
};
