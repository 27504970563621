import React, { useEffect, useState } from "react";
import { useApi } from "../../services/HttpService";
import { AdminTable } from "../../components/tables/AdminTable";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Alert, CircularProgress, TextField } from "@mui/material";

interface Tables {
  [key: string]: string;
}

export const TableOverview = () => {
  const { api, response, isLoading, error } = useApi();
  const {
    api: tableApi,
    response: tableResponse,
    isLoading: tableIsLoading,
    error: tableError,
  } = useApi();

  const [tables, setTables] = useState<Tables>({});
  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [columnTypes, setColumnTypes] = useState<any>({});
  const [selectedTable, setSelectedTable] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function getTables() {
      await tableApi("tables", "GET");
    }
    getTables();
  }, []);

  useEffect(() => {
    if (tableResponse) {
      setTables(tableResponse.tables);
    }
  }, [tableResponse]);

  const getTableData = (tableName: string) => {
    setSelectedTable(tableName);

    api("tables", "POST", {
      name: tableName,
      page_index: pageIndex,
      items_per_page: itemsPerPage,
    });
  };

  useEffect(() => {
    if (response) {
      // console.log(response)
      if (response.data) {
        setRows(response.data);
        // var columns = Object.entries(response.data[0]).map(([key, value]) => { return key });
        setFilteredRows(response.data); // Initialize filteredRows with all rows
        setColumns(response.columns);
        setColumnTypes(response.column_types);
      } else {
        setRows([]);
        setFilteredRows([]);
        setColumns([]);
      }
    }
  }, [response]);

  // Filter rows based on searchQuery
  useEffect(() => {
    const filtered = rows.filter((row) =>
      Object.values(row).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      })
    );
    setFilteredRows(filtered);
  }, [searchQuery, rows]);

  const addFilter = (filter: string) => {};

  return (
    <div className="App">
      <header className="App-header">
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: "15%",
              display: "flex",
              p: 2,
              flexDirection: "column",
            }}
          >
            {Object.entries(tables).map(([key, value]) => (
              <Button
                key={key}
                variant={selectedTable === value ? "contained" : "outlined"}
                style={{
                  minWidth: "100px",
                  textTransform: "none",
                  wordBreak: "break-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sx={{ pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 } }}
                onClick={() => getTableData(value)}
              >
                {key}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              width: "85%",
              display: "flex",
              p: 2,
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {error && (
              <Alert
                variant="outlined"
                severity="error"
                sx={{ width: "100%", mb: 2 }}
              >
                {error}
              </Alert>
            )}
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ marginBottom: "1rem" }}
            />
            <AdminTable
              title={selectedTable}
              rows={filteredRows} // Display filtered rows}
              columns={columns}
              columnTypes={columnTypes}
              addFilter={addFilter}
            />
          </Box>
        </Box>
      </header>
    </div>
  );
};
