import React from 'react';

export const Tariff = () => {
  return (
    <div className="App">
      <header className="App-header">
        Tariff page
      </header>
    </div>
  )
}