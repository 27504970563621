import { registerWidget, registerWidgetStyleOption } from "dmeditor";
import definition from "./definition";
import { LayoutPosterLeftright } from "./LayoutPosterLeftright";

export default () => {
  registerWidgetStyleOption("parat-layout-poster-leftright", [
    {
      name: "Default",
      identifier: "default",
      cssStyle: `
        
      `,
    },
  ]);
  registerWidget(definition, { render: LayoutPosterLeftright });
};
