import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UseIcon from "@mui/icons-material/TouchApp";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { convertHtmlToPdf } from "../lib/convertToPdf";
import { NewsletterResponse, TemplateResponse } from "../interfaces";
import checkPermission from "./permissions";
import { useDcpAuth } from "../../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../../services/DcpService";

interface NewsletterProps {
  newsletter: NewsletterResponse | TemplateResponse;
  isDownloadPage?: boolean;
  currentNewsletters?: NewsletterResponse[];
  setCurrentNewsletters?: Function;
  layout: string;
}

const NewsletterCard: React.FC<NewsletterProps> = ({
  newsletter,
  isDownloadPage,
  currentNewsletters,
  setCurrentNewsletters,
  layout,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(newsletter.title);
  // const [imageUrl, setImageUrl] = useState<string>("");
  const navigate = useNavigate();
  // const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();
  const {
    dcpApi: deleteApi,
    dcpResponse: deleteResponse,
    dcpIsLoading: deleteIsLoading,
    dcpError: deleteError,
  } = useDcpApi();
  const { role } = useDcpAuth();

  const { t } = useTranslation("NewsletterCardPage");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // edit
  const handleEditDialog = () => {
    let data = [];
    if (newsletter.body) data = newsletter.body.data;
    navigate("/poster/edit/newsletter/" + newsletter.id);
    handleClose();
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(event.target.value);
  };

  // delete

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(true);
    handleClose();
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteSubmit = async () => {
    await deleteApi(
      availablePaths.newsletterApi.deleteNewsLetter(
        newsletter.location.content_id
      ),
      "GET"
    );
  };

  useEffect(() => {
    if (deleteResponse) {
      let cp = currentNewsletters!.filter(
        (news) => news.location.content_id !== newsletter.location.content_id
      );
      setCurrentNewsletters!(cp);

      toast.success(t("Deleted successfully"));
      handleDeleteClose();
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (deleteError) {
      console.error("Failed to update:", deleteError);
      toast.error(t("There was an error deleting the newsletter"));
    }
  }, [deleteError]);

  return (
    <div
      className={
        layout === "card"
          ? "rounded overflow-hidden shadow-xl hover:shadow-2xl transition-shadow duration-300 ease-in-out"
          : "flex rounded overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out items-center"
      }
    >
      <img
        src={
          (newsletter as NewsletterResponse).preview
            ? availablePaths.imageUpload.getImageByPrefix(
                (newsletter as NewsletterResponse).preview
              )
            : "https://cdn.pixabay.com/photo/2024/02/24/20/54/books-8594725_1280.jpg"
        }
        alt="cover_image"
        className={
          layout === "card"
            ? "w-full h-64 object-cover object-top"
            : "w-64 h-48 object-cover object-top"
        }
      />
      <div className="p-4 flex-grow">
        <h2 className="text-slate-700 font-bold text-xl">{newsletter.title}</h2>
        <div
          className={
            layout === "card"
              ? "text-slate-600 flex justify-between mt-3"
              : "text-slate-600 flex flex-col mt-3"
          }
        >
          <div
            className={
              layout === "card" ? "flex items-center" : "flex items-center mr-3"
            }
          >
            {/* {imageUrl.length > 0 ? (
              <img
                src={availablePaths.imageUpload.getImageByPrefix(imageUrl)}
                className=" h-7 w-7 rounded-full"
              />
            ) : ( */}
            <PersonIcon />
            {/* )} */}
            <span className="ml-1">
              {t("By")} - {newsletter.metadata.author_name}
            </span>
          </div>
          <div className="flex items-center">
            <DateRangeIcon />
            <span className="ml-1">
              {new Date(newsletter.metadata.modified).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>

      <div
        className={
          layout === "card"
            ? "px-4 pb-4 flex justify-end items-center"
            : "flex justify-end items-center pr-4"
        }
      >
        {isDownloadPage ? (
          <>
            {" "}
            {checkPermission(role, "download", "download") && (
              <button
                onClick={() =>
                  convertHtmlToPdf(newsletter.body, newsletter.title)
                }
                className="flex items-center text-sm font-bold"
              >
                <DownloadForOfflineIcon />
                <span className="ml-1">{t("Download")}</span>
              </button>
            )}{" "}
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {" "}
              {checkPermission(role, "download", "edit") && (
                <MenuItem onClick={handleEditDialog}>
                  <EditIcon />
                  <span className="ml-1 ring-offset-neutral-600">
                    {t("Edit")}
                  </span>
                </MenuItem>
              )}{" "}
              {checkPermission(role, "download", "delete") && (
                <MenuItem onClick={handleDeleteDialog}>
                  <DeleteIcon />
                  <span className="ml-1">{t("Delete")}</span>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <>
            {checkPermission(role, "newsletter", "edit") && (
              <button
                onClick={handleEditDialog}
                className="flex items-center text-sm font-bold"
              >
                <EditIcon />
                <span className="ml-1">{t("Edit")}</span>
              </button>
            )}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {" "}
              {checkPermission(role, "newsletter", "download") && (
                <MenuItem
                  onClick={() =>
                    convertHtmlToPdf(newsletter.body, newsletter.title)
                  }
                >
                  <DownloadForOfflineIcon />
                  <span className="ml-1">{t("Download")}</span>
                </MenuItem>
              )}
              {checkPermission(role, "newsletter", "use") && (
                <MenuItem onClick={handleDeleteDialog}>
                  <DeleteIcon />
                  <span className="ml-1">{t("Delete")}</span>
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </div>

      {/* delete dialog  */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          {t("Do you want to delete")} {newsletter.title} ?
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleDeleteClose}>{t("Cancel")}</Button>
          <Button onClick={handleDeleteSubmit}>{t("Delete")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsletterCard;
