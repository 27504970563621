import React, { useEffect, useState, useRef } from "react";
import { DMEData, DMEditor, DMEditorView } from "dmeditor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sendDataToBackend } from "./lib/ConvertImage";
import { useDcpAuth } from "../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../services/DcpService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import { DMEditorRefType } from "dmeditor/core/main/designer/DMEditor";
import { Box, CircularProgress } from "@mui/material";
import "./styles/browse-image.css";
import { Saving } from "./components/Saving";
import { DMEditorWithPreview } from "./DMEditorWithPreview";

const dmeditorCss = css`
  legend {
    font-size: 1rem;
    width: auto;
    margin-top: -1rem;
    background: #f0f0f0;
    border-radius: 4px;
    font-weight: 500;
    color: #1d4b64;
  }
`;

const Edit = () => {
  const { id, type: contentType } = useParams();
  const editorRef = useRef<DMEditorRefType>(null);
  const navigate = useNavigate();
  const { accessToken } = useDcpAuth();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { dcpApi, dcpResponse, dcpError } = useDcpApi();
  const [blockData, setBlockData] = useState([]);
  const [title, setTitle] = useState("");

  const { t } = useTranslation("UseEditContentPage");

  const saveContent = async (data: any) => {
    const editorData = data.data;
    setIsSaving(true);

    try {
      const fileName: string = "MyPoster.png";
      const payloadKey: string = "file";
      let previewUrl = "";
      const reqMethod = "POST";
      const reqURL = availablePaths.imageUpload.uploadImageFile();
      const responseData = await sendDataToBackend(
        "dmeditor-view",
        fileName,
        payloadKey,
        reqURL,
        reqMethod
      );
      if (!responseData.error) previewUrl = responseData.data;

      let payload = {};
      let url = "";
      let idInt = parseInt(id);
      if (contentType === "template") {
        url = availablePaths.templateApi.updateTemplate(idInt);
        payload = {
          body: editorData,
          title: data?.page?.title ?? "",
          preview: previewUrl,
        };
      } else {
        payload = {
          body: editorData,
          title: data?.page?.title ?? "",
          preview: previewUrl,
          pdf: "",
        };
        if (contentType === "poster") {
          url = availablePaths.posterApi.updatePoster(idInt);
        } else if (contentType === "newsletter") {
          url = availablePaths.newsletterApi.updateNewsletter(idInt);
        }
      }

      await dcpApi(url, "POST", payload);
      toast.success("Saved successfully");
      navigate(-1);
    } catch {
      toast.error(t("There was an error saving the content"));
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (dcpResponse) {
      setIsSaving(false);
    }
  }, [dcpResponse]);

  useEffect(() => {
    if (dcpError) {
      toast.error(t("There was an error saving the content"));
      setIsSaving(false);
    }
  }, [dcpError]);

  const getDataPath = () => {
    const idInt = parseInt(id);
    switch (contentType) {
      case "template":
        return availablePaths.templateApi.getTemplateById(idInt);
      case "poster":
        return availablePaths.posterApi.getPosterById(idInt);
      case "newsletter":
        return availablePaths.newsletterApi.getNewsletterById(idInt);
    }
    return "";
  };

  useEffect(() => {
    if (accessToken) {
      const path = getDataPath();
      if (!path) {
        console.error("Invalid type or id");
        return;
      }

      dcpApi(path, "GET").then((data) => {
        const contentData = data.data;
        setBlockData(contentData.body);
        setTitle(contentData.title);
      });
    }
  }, [accessToken]);

  if (!accessToken) {
    return <div>Need access token.</div>;
  }

  return (
    <div className={dmeditorCss}>
      {isSaving && <Saving />}
      <DMEditorWithPreview
        data={blockData}
        title={title}
        onSave={saveContent}
        onCancel={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};

export default Edit;
