import { registerWidget, registerWidgetStyleOption } from "dmeditor";
import definition from "./definition";
import { LayoutPoster } from "./LayoutPoster";

export default () => {
  registerWidgetStyleOption("parat-layout-poster", [
    {
      name: "Default",
      identifier: "default",
      cssStyle: `
        
      `,
    },
  ]);
  registerWidget(definition, { render: LayoutPoster });
};
