import React, { useState, useCallback } from "react";
import { DcpAuthProvider, useDcpAuth } from "../contexts/DcpAuthContext";
import localStorageService, { LocalStorageKeys } from "./LocalStorageService";

const baseURL = process.env.REACT_APP_DCP_URL;

/**
 * Generic function for handeling api calls to dcp
 * used by: pages/public/poster and contexts/DcpAuthContext.tsx
 */
export const useDcpApi = () => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>();
  const { setTokens } = useDcpAuth();

  const api = async (
    path: string,
    method: string,
    body?: object
  ): Promise<any> => {
    setIsLoading(true);
    setError("");

    const tokens = localStorageService.getItem(LocalStorageKeys.DCP_TOKENS);
    // console.log("calling dcp api:", path, "using accessToken", tokens.accessToken);

    try {
      const options: RequestInit = {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (method !== "GET" && body) {
        options.body = JSON.stringify(body);
      }

      const response = await FetchWithAuth(path, options, tokens.accessToken);
      const json = await response.json();

      if (!response.ok) {
        if (json?.data?.message.toLowerCase() === "invalid token") {
          localStorageService.removeItem(LocalStorageKeys.DCP_TOKENS);
          window.location.reload();
          return;
        }
        let message = json?.data?.message
          ? json.data.message
          : "Status: " + response.status;
        setError(message);
        setIsLoading(false);
        return;
      }

      setResponse(json);
      setIsLoading(false);
      return json;
    } catch (error) {
      setError("Noe gikk galt, prøv igjen senere.");
      setIsLoading(false);
      return;
    }
  };

  return {
    dcpApi: api,
    dcpResponse: response,
    dcpIsLoading: isLoading,
    dcpError: error,
  };
};

export const multipartApi = async (
  path: string,
  body: FormData
): Promise<any> => {
  const tokens = localStorageService.getItem(LocalStorageKeys.DCP_TOKENS);
  // console.log("calling dcp api:", path, "using accessToken", tokens.accessToken);

  try {
    const options: RequestInit = {
      method: "POST",
      body: body,
    };

    const response = await FetchWithAuth(path, options, tokens.accessToken);
    const json = await response.json();

    if (!response.ok) {
      return;
    }

    return json;
  } catch (error) {
    return;
  }
};

const FetchWithAuth = async (
  url: string,
  options: RequestInit,
  accessToken: string,
  preventRefetch: boolean = false
): Promise<Response> => {
  const authHeader = `Bearer ${accessToken ?? ""}`;
  const headersInstance: HeadersInit = {
    ...options.headers,
    Authorization: authHeader,
  };

  const requestOptions: RequestInit = {
    ...options,
    headers: headersInstance,
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.status === 440 && !preventRefetch) {
      // If token expired
      const tokens = localStorageService.getItem(LocalStorageKeys.DCP_TOKENS);
      if (tokens?.refreshToken) {
        const newAccessToken = await GetNewAccessToken(tokens?.refreshToken);
        if (newAccessToken) {
          let refreshToken = tokens.refreshToken;
          localStorageService.setItem(LocalStorageKeys.DCP_TOKENS, {
            accessToken: newAccessToken,
            refreshToken: refreshToken,
          });

          // @todo: setToken cannot be called from here, not within DcpAuthContext (i think)
          // setTokens(data.data, refreshToken);
          return FetchWithAuth(url, requestOptions, newAccessToken, true); // Retry request with updated accessToken
        } else {
          localStorageService.removeItem(LocalStorageKeys.DCP_TOKENS);
          window.location.reload();
        }
      }
    }
    return response;
  } catch (error) {
    console.error("FetchWithAuth error:", error, preventRefetch);
    if (!preventRefetch) {
      // If token expired
      const tokens = localStorageService.getItem(LocalStorageKeys.DCP_TOKENS);
      if (tokens?.refreshToken) {
        const newAccessToken = await GetNewAccessToken(tokens?.refreshToken);
        if (newAccessToken) {
          let refreshToken = tokens.refreshToken;
          localStorageService.setItem(LocalStorageKeys.DCP_TOKENS, {
            accessToken: newAccessToken,
            refreshToken: refreshToken,
          });

          // @todo: setToken cannot be called from here, not within DcpAuthContext (i think)
          // setTokens(data.data, refreshToken);
          return FetchWithAuth(url, requestOptions, newAccessToken, true); // Retry request with updated accessToken
        } else {
          localStorageService.removeItem(LocalStorageKeys.DCP_TOKENS);
          window.location.reload();
        }
      }
    } else {
      throw error;
    }
  }
};

export const GetNewAccessToken = async (
  refreshToken: string
): Promise<string | null> => {
  if (!refreshToken) {
    return null;
  }

  try {
    const response = await fetch(
      availablePaths.authApi.renewRefreshToken(refreshToken),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    return data.data; // new access token
  } catch (error) {
    console.error("Error getting new access token:", error);
    return null;
  }
};

export const availablePaths = {
  baseURL: {
    rootUrl: () => baseURL,
  },
  authApi: {
    renewRefreshToken: (refreshToken: string) =>
      baseURL + `api/auth/token/access/renew?token=${refreshToken}`,
    authenticate: () => baseURL + "api/auth/auth",
  },
  posterApi: {
    createPoster: () => baseURL + `api/content/create/poster/494`,
    posterList: () =>
      baseURL +
      "api/content/list/poster?parent=494&level=1&sortby=priority%20desc%3Bmodified%20desc&limit=10&offset=0",
    updatePoster: (id: number) => baseURL + `api/content/update/poster/${id}`,
    deletePoster: (id: number) =>
      baseURL + `api/content/delete?cid=${id}&type=poster`,
    getPosterById: (id: number) => baseURL + `api/content/get/poster/${id}`,
  },
  newsletterApi: {
    createNewsLetter: () => baseURL + "api/content/create/newsletter/493",
    getNewsletterList: () =>
      baseURL +
      "api/content/list/newsletter?parent=493&level=1&sortby=priority%20desc%3Bmodified%20desc&limit=100&offset=0",
    updateNewsletter: (id: number) =>
      baseURL + `api/content/update/newsletter/${id}`,
    deleteNewsLetter: (id: number) =>
      baseURL + `api/content/delete?cid=${id}&type=newsletter`,
    getNewsletterById: (id: number) =>
      baseURL + `api/content/get/newsletter/${id}`,
  },
  templateApi: {
    createTemplate: () => baseURL + "api/content/create/template/495",
    getTemplateList: () =>
      baseURL +
      "api/content/list/template?parent=495&level=1&sortby=priority%20desc%3Bmodified%20desc&limit=10&offset=0",
    updateTemplate: (id: number) =>
      baseURL + `api/content/update/template/${id}`,
    deleteTemplate: (id: number) =>
      baseURL + `api/content/delete?cid=${id}&type=template`,
    getTemplateById: (id: number) => baseURL + `api/content/get/template/${id}`,
  },
  users: {
    getCurrentAdminInfo: () => baseURL + "api/user/current/admin",
    getUserById: (id: number) => baseURL + `api/content/get/user/${id}`,
    getUserList: () => baseURL + `api/content/list/user?parent=535&level=1`,
    createUser: () => baseURL + `api/content/create/user/535`,
    updateUserByID: (id: number) => baseURL + `api/content/update/user/${id}`,
    deleteUser: (id: number) =>
      baseURL + `api/content/delete?cid=${id}&type=user`,
    userRole: (id: number) => baseURL + `api/user/roles/${id}`,
  },
  roles: {
    getRoleList: () => baseURL + `api/content/list/role?limit=-1`,
    createRole: () => baseURL + `api/content/create/role/7`,
    updateRole: (id: number) => baseURL + `api/content/update/role/${id}`,
    getRoleWithID: (id: number) => baseURL + `api/content/get/role/${id}`,
    deleteRole: (id: number) =>
      baseURL + `api/content/delete?cid=${id}&type=role`,
    assignRoles: (id: number) => baseURL + `api/content/update/role/${id}`,
    assignRoleToUser: (userID: number, roleID: string) =>
      baseURL + `api/access/assign/${userID}/${roleID}`,
  },

  imageUpload: {
    uploadImageFile: () => baseURL + "api/util/uploadfile?service=content",
    getImageByPrefix: (prefix: string) => baseURL + "var/" + prefix,
  },
};
