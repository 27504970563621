import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
const definition: DME.Widget = {
  category: "basic",
  icon: "parat-logo",
  name: "Newsletter line",
  type: "parat-line",
  events: {
    createBlock: (): DMEData.Block => {
      return {
        id: nanoid(),
        type: "parat-line",
        data: {},
      };
    },
    updateData: () => {},
  },
  settings: [],
};

export default definition;
