import { Box, CircularProgress } from "@mui/material";

export const Saving = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          padding: "10px 20px 10px 20px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <h2 style={{ paddingRight: "16px" }}>Saving</h2>
        <CircularProgress color="info" />
      </Box>
    </Box>
  );
};
