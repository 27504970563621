import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  display: flex;

  height: 900px;
  overflow-y: hidden;

  & > .dme-w-coverimage {
    width: 66.67%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  background-color: var(--parat-orange);
  color: white;

  & > .dme-w-list {
    width: 33.33%;
  }

  .footer {
    text-align: right;
    img {
      width: 100px;
    }
    position: absolute;
    bottom: 10px;
    right: 5px;
  }

  .dme-w-mainlist {
    padding: 5px 20px;
    max-height: 750px;
    overflow-y: auto;

    .dme-block-container {
      margin-top: 10px;
    }
  }

  .logo {
    text-align: right;
    img {
      width: 100px;
    }
  }
`;
