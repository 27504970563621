import { BlockListRender, DME } from "dmeditor";
import { NewsLetterHeaderEntity } from "./entity";
import { ListContainer } from "./styled";

export const NewsLetterHeader = (
  props: DME.WidgetRenderProps<NewsLetterHeaderEntity>
) => {
  return (
    <div className={props.rootClasses}>
      <ListContainer>
        <BlockListRender
          blockData={props.blockNode.children || []}
          path={props.path}
          mode={props.mode}
          direction="horizontal"
        />
      </ListContainer>
    </div>
  );
};
