import { BlockListRender, DME } from "dmeditor";
import { LayoutPosterLeftrightEntity } from "./entity";
import { LayoutContainer } from "./styled";

const dcpUrl = process.env.REACT_APP_DCP_URL;

export const LayoutPosterLeftright = (
  props: DME.WidgetRenderProps<LayoutPosterLeftrightEntity>
) => {
  return (
    <LayoutContainer className={props.rootClasses}>
      <div className="dme-w-coverimage">
        <img src={props.blockNode.data.coverImage} />
      </div>
      <div className="dme-w-list">
        <div className="logo">
          <img src={dcpUrl + "var/images/e/eux/upload-226402980-screenshot_2024-04-29_at_15.10.31.png"} />
        </div>
        <div className="dme-w-mainlist">
          <BlockListRender
            blockData={props.blockNode.children || []}
            path={props.path}
            mode={props.mode}
          />
        </div>
        <div className="footer">
          <img src={dcpUrl + "var/images/j/jum/upload-4067947600-screenshot_2024-04-29_at_15.08.18.png"} />
        </div>
      </div>
    </LayoutContainer>
  );
};
