import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions,
  ThemeProvider,
  CssBaseline,
  GlobalStyles,
} from "@mui/material";
import { Theme, lighten, darken } from "@mui/material/styles";
import React from "react";
import { create } from "zustand";
import { useApi } from "../../services/HttpService";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import localStorageService, {
  LocalStorageKeys,
} from "../../services/LocalStorageService";

/**
 * Global theme that is always applied
 */

declare module "@mui/material/styles" {
  interface Palette {
    darkgrey: Palette["primary"];
  }

  interface PaletteOptions {
    darkgrey?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkgrey: true;
  }
}

let defaultTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#fff",
    },
    darkgrey: {
      main: "#646464",
      contrastText: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTablePagination: {
      // remove margin of labels from TabelPagination
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        displayedRows: {
          margin: 0,
        },
      },
    },
  },
});

type ColorMode = "light" | "dark";

type ThemeState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  setMode: (colorMode: ColorMode) => void;
  updateColors: (
    primary: string | undefined,
    secondary: string | undefined,
    success: string | undefined
  ) => void;
};

// Global theme store, can be used to updated theme from anywhere
export const themeStore = create<ThemeState>((setState) => ({
  theme: defaultTheme,
  setTheme: (theme) => setState((state) => ({ ...state, theme })),
  setMode: (colorMode) => {
    const currentState = themeStore.getState();
    const updatedTheme = createTheme({
      ...currentState.theme,
      palette: {
        ...currentState.theme.palette,
        background: {
          default: colorMode === "light" ? "#fff" : "#282c34",
        },
        text: {
          primary: colorMode === "light" ? "#000" : "#fff",
        },
        mode: colorMode,
      },
    });

    themeStore.setState({ ...currentState, theme: updatedTheme });
    localStorageService.setItem(LocalStorageKeys.COLOR_MODE, colorMode);
  },
  updateColors: (primary, secondary, success) => {
    const currentState = themeStore.getState();
    const updatedTheme = createTheme({
      ...currentState.theme,
      palette: {
        ...currentState.theme.palette,
        primary: {
          main: primary ? primary : currentState.theme.palette.primary.main,
        },
        secondary: {
          main: secondary
            ? secondary
            : currentState.theme.palette.secondary.main,
        },
        success: {
          main: success ? success : currentState.theme.palette.success.main,
        },
      },
    });

    themeStore.setState({ ...currentState, theme: updatedTheme });
  },
}));

interface Props {
  children: JSX.Element | JSX.Element[];
}
const BaseTheme = ({ children }: Props) => {
  const { api, response, isLoading, error } = useApi();
  const [theme, setTheme] = React.useState(themeStore.getState().theme); // ensures rerender of theme when changed
  const [initialLoad, setInitialLoad] = React.useState(false); // prevent flash of default colors when page is reloaded

  React.useEffect(() => {
    const getTheme = async () => {
      await api("/getTheme", "POST");
    };
    getTheme();

    const colorMode = localStorageService.getItem(LocalStorageKeys.COLOR_MODE);
    if (colorMode) {
      themeStore.getState().setMode(colorMode);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = themeStore.subscribe((newState) => {
      setTheme(newState.theme);
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    if (response) {
      themeStore
        .getState()
        .updateColors(response.primary, response.secondary, response.success);
    }

    setInitialLoad(true);
  }, [response, error]);

  return (
    <>
      {initialLoad && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      )}
    </>
  );
};

export default BaseTheme;
