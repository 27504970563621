import React from 'react';

export const Kommunikasjon = () => {
  return (
    <div className="App">
      <header className="App-header">
        Kommunikasjon page
      </header>
    </div>
  )
}