import styled from "@emotion/styled";

export const ListContainer = styled.div`
  display: flex;
  padding: 20px 5px;

  & > div {
    width: 50%;
  }

  & .dme-block-container:first-child img {
    max-width: 200px;
  }

  & .dme-block-container:last-child {
    img {
      width: 100px;
    }
    text-align: right;
  }
`;
