import React from 'react';
import EnhancedTable from '../components/tables/BaseTable';

export const Organisasjon = () => {
  return (
    <div className="App">
      <header className="App-header">
        Organisasjon page
        <EnhancedTable />
      </header>
    </div>
  )
}