import React, { useState, useCallback } from "react";
import Button from '@mui/material/Button';
import { SxProps, Theme, Tooltip, TooltipProps } from "@mui/material";

interface DownloadButtonProps {
  path: string,
  fileName: string,
  buttonText: string,
  tooltip?: string,
  tooltipDir?: TooltipProps["placement"],
  sx?: SxProps<Theme>
}

export const PrivateFileDownloader: React.FC<DownloadButtonProps> = ({ path, fileName, buttonText, tooltip, tooltipDir, sx }) => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);

  const handleDownload = async () => {
    setIsLoading(true);
    setError('');

    var token = localStorage.getItem("token");

    try {
      const response = await fetch(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();

      if (!response.ok) {
        if (response.status === 401) {
          setError("ikke tilgang")
        } else {
          setError("Error, prøv igjen senere")
        }
      }

      if (response.ok) {
        const url = window.URL.createObjectURL(new Blob([blob]));

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        // Click the link to start the download
        link.click();
        link.parentNode?.removeChild(link); // Cleanup
        setIsDownloaded(true)
      }

      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching the file:', error);
      setError("Error, prøv igjen senere")
      setIsLoading(false)
    }
  };

  return (
    <>
      {isDownloaded ? (
        <p>Filen er lasted ned</p>
      ) : (
        <Tooltip title={tooltip} placement={tooltipDir}>
          <Button
            color={error === "" ? "success" : "error"}
            variant="contained"
            disabled={isLoading}
            onClick={handleDownload}
            size="medium"
            sx={sx}
          >
            {error === "" ? buttonText : error}
          </Button >
        </Tooltip >
      )}
    </>
  );
};