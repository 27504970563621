import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../pages';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:formstep" element={<Login />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};