import React, { useEffect } from "react";
import { AppRouter } from "./routes/AppRouter";
import BaseLayout from "./components/layouts/BaseLayout";
import { create } from "zustand";
import "./App.css";
import "./pages/public/poster/components/i18n";
import { AuthService } from "./services/AuthService";

export const authStore = create<{
  isLoggedin: boolean;
  initialLoad: boolean;
  userName: string;
  userRoles: string[];
}>((setState, getState) => ({
  isLoggedin: false,
  initialLoad: false,
  userName: "",
  userRoles: [],
}));

function App() {
  const { authenticate, logout } = AuthService();

  useEffect(() => {
    authenticate().then((success) => {
      if (!success) {
        logout();
      }

      authStore.setState({ initialLoad: true });
    });
  }, []);

  return (
    <BaseLayout>
      <AppRouter />
    </BaseLayout>
  );
}

export default App;
