import { Box, CircularProgress } from "@mui/material";
import { DMEData, DMEditor, DMEditorRefType, DMEditorView } from "dmeditor";
import { useEffect, useRef, useState } from "react";
import { css } from "@emotion/css";

export type DMEditorWithPreviewProps = {
  data: DMEData.BlockList;
  title: string;
  onSave?: (data) => void;
  onCancel?: () => void;
  onChange?: (data) => void;
};

export const DMEditorWithPreview = (props: DMEditorWithPreviewProps) => {
  const editorRef = useRef<DMEditorRefType>();
  const [previwData, setPreviewData] = useState<DMEData.BlockList>([]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setData(props.data || []);
      editorRef.current.setPageData({
        title: props.title,
      });
    }
  }, [props.data, props.title]);

  return (
    <>
      <div style={{ position: "absolute", top: "-10000px" }}>
        <div id="dmeditor-view" style={{ width: "1100px" }}>
          <DMEditorView data={previwData}></DMEditorView>
        </div>
      </div>

      <div
        className={css`
          ul {
            list-style: initial;
            padding: inherit;
          }
        `}
      >
        <DMEditor
          ref={editorRef}
          onSave={(data) => props.onSave(data)}
          onCancel={props.onCancel}
          onChange={(data) => {
            setPreviewData(data.data);
          }}
        />
      </div>
    </>
  );
};
