import React, { useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { useApi } from "../services/HttpService";

export const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <>
          <h4>Home page</h4>
        </>
      </header>
    </div>
  );
};
