import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { Alert, CircularProgress } from "@mui/material";
import {
  GroupLevel,
  FilterData,
  SelectedFilter,
  SelectedGroup,
  SaleryStatistic,
} from "../Interface";
import { useApi } from "../../../../services/HttpService";
import { useParams } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { PrivateFileDownloader } from "../../../../components/file/PrivateFileDownloader";
import { ItemCard } from "../../../../components/ui/ItemCard";

interface TableHolderProps {
  selectedFilters: SelectedFilter[];
}

const displayOptions = [
  { id: 0, name: "Linjediagram" },
  { id: 1, name: "Søylediagram" },
  { id: 2, name: "Tabell" },
  { id: 3, name: "Tabell med all data" },
];

function createSaleryStatistic(
  name: string,
  stat_year: number,
  salary_agreed_avg: number,
  salary_agreed_med: number,
  salary_avg: number,
  salary_med: number,
  bonus_avg: number,
  bonus_med: number,
  addition_avg: number,
  addition_med: number,
  non_member: boolean = false
) {
  let s: SaleryStatistic = {
    name,
    stat_year,
    salary_agreed_avg,
    salary_agreed_med,
    salary_avg,
    salary_med,
    bonus_avg,
    bonus_med,
    addition_avg,
    addition_med,
    non_member,
  };
  return s;
}

interface PlotLookup {
  name: string;
  dataKey: string;
  stroke: string;
  strokeWidth: number;
  activeDot: number;
  stackId: string;
}

interface DisplayData {
  stat_year: number;
  [key: string]: number;
}

export const TableHolder: React.FC<TableHolderProps> = ({
  selectedFilters,
}) => {
  const { uid } = useParams();
  const { api, response, isLoading, error } = useApi();
  // const [header, setHeader] = React.useState("Tabell")
  const [selectedIndex, setSelectedIndex] = React.useState(0); // data display type (grapth/table)
  const [rows, setRows] = React.useState<SaleryStatistic[]>([]); // all data from filters (members and non-members)
  const [selectedValue, setSelectedValue] = React.useState("avg"); // radio, gjennomsnitt/median
  const [checkedCompare, setCheckedCompare] = React.useState(false); // chckbox, compare with non-members
  const [checkedBonus, setCheckedBonus] = React.useState(false); // chckbox, display bonus
  const [checkedAddition, setCheckedAddition] = React.useState(false); // chckbox, display addition
  // const [domainY, setDomainY] = React.useState<[number, number]>([0, 50000]) // range of Y-axis (done inline)
  const [displayData, setDisplayData] = React.useState<DisplayData[]>([]); // data shown in plots
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]); // keys of data shown in plots
  const theme = useTheme();

  const DisplayDataLookup: PlotLookup[] = [
    {
      name: "Avtalt snitt lønn",
      dataKey: "salary_agreed_avg",
      stroke: "#32618F",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_agr_avg",
    },
    {
      name: "Snitt lønn",
      dataKey: "salary_avg",
      stroke: `${theme.palette.primary.main}`,
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_avg",
    },
    {
      name: "Avtalt median lønn",
      dataKey: "salary_agreed_med",
      stroke: "#32618F",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_agr_med",
    },
    {
      name: "Median lønn",
      dataKey: "salary_med",
      stroke: `${theme.palette.primary.main}`,
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_med",
    },
    {
      name: "Snitt bonus",
      dataKey: "bonus_avg",
      stroke: "#A70F00",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_avg",
    },
    {
      name: "Median bonus",
      dataKey: "bonus_med",
      stroke: "#A70F00",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_med",
    },
    {
      name: "Snitt ekstra lønn",
      dataKey: "addition_avg",
      stroke: "#630000",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_avg",
    },
    {
      name: "Median ekstra lønn",
      dataKey: "addition_med",
      stroke: "#630000",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "sal_med",
    },
    {
      name: "Avtalt snitt lønn (ikke medlem)",
      dataKey: "ssb_salary_agreed_avg",
      stroke: "#28A215",
      strokeWidth: 2,
      activeDot: 6,
      stackId: "ssb_sal_agr_avg",
    },
    {
      name: "Snitt lønn (ikke medlem)",
      dataKey: "ssb_salary_avg",
      stroke: "#AB49BC",
      strokeWidth: 2,
      activeDot: 6,
      stackId: "ssb_sal_avg",
    },
    {
      name: "Avtalt median lønn (ikke medlem)",
      dataKey: "ssb_salary_agreed_med",
      stroke: "#28A215",
      strokeWidth: 2,
      activeDot: 6,
      stackId: "ssb_sal_agr_med",
    },
    {
      name: "Median lønn (ikke medlem)",
      dataKey: "ssb_salary_med",
      stroke: "#AB49BC",
      strokeWidth: 2,
      activeDot: 6,
      stackId: "ssb_sal_med",
    },
    {
      name: "Snitt bonus (ikke medlem)",
      dataKey: "ssb_bonus_avg",
      stroke: "#5CBAB3",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "ssb_sal_avg",
    },
    {
      name: "Median bonus (ikke medlem)",
      dataKey: "ssb_bonus_med",
      stroke: "#5CBAB3",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "ssb_sal_med",
    },
    {
      name: "Snitt ekstra lønn (ikke medlem)",
      dataKey: "ssb_addition_avg",
      stroke: "#48817C",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "ssb_sal_avg",
    },
    {
      name: "Median ekstra lønn (ikke medlem)",
      dataKey: "ssb_addition_med",
      stroke: "#48817C",
      strokeWidth: 2,
      activeDot: 8,
      stackId: "ssb_sal_med",
    },
  ];

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  React.useEffect(() => {
    const getSelectableFilters = async () => {
      let ids = ["gl2", "gl3", "gl4", "gl5", "gl6"];

      let data: { uid: string | undefined; [key: string]: any } = { uid: uid };
      ids.forEach((id) => {
        let index = selectedFilters.findIndex((item) => item.id === id);
        if (index > -1) {
          let value = selectedFilters[index].filter.id;
          data[id] = value;
        }
      });

      ///////// TEST REMOVE /////////
      // data = { "uid": "asd", "gl2": 4, "gl3": 4, "gl4": 12, "gl5": 64, "gl6": 512 }
      ///////// TEST REMOVE /////////

      await api("getSalaryStatistic", "POST", data);
    };
    getSelectableFilters();
  }, [selectedFilters]);

  React.useEffect(() => {
    var stats: SaleryStatistic[] = [];
    var num = 1;

    var memberData = response?.data?.memberData;
    var nonMemberData = response?.data?.nonMemberData;

    if (memberData) {
      memberData.forEach((e: any) => {
        stats.push(
          createSaleryStatistic(
            "Medlem",
            e.stat_year,
            e.salary_agreed_avg,
            e.salary_agreed_med,
            e.salary_avg,
            e.salary_med,
            e.bonus_avg,
            e.bonus_med,
            e.addition_avg,
            e.addition_med
          )
        );
        num += 1;
      });
    }
    if (nonMemberData) {
      nonMemberData.forEach((e: any) => {
        stats.push(
          createSaleryStatistic(
            "Ikke medlem",
            e.stat_year,
            e.salary_agreed_avg,
            e.salary_agreed_med,
            e.salary_avg,
            e.salary_med,
            e.bonus_avg,
            e.bonus_med,
            e.addition_avg,
            e.addition_med,
            true
          )
        );
        num += 1;
      });
    }

    stats.sort((a, b) => (a.stat_year > b.stat_year ? 1 : -1));
    let displayData: DisplayData[] = [];

    stats.forEach((s) => {
      let i = displayData.findIndex((i) => i.stat_year === s.stat_year);
      if (i > -1) {
        if (s.non_member) {
          displayData[i].ssb_salary_agreed_avg = s.salary_agreed_avg;
          displayData[i].ssb_salary_agreed_med = s.salary_agreed_med;
          displayData[i].ssb_salary_avg = s.salary_avg;
          displayData[i].ssb_salary_med = s.salary_med;
          displayData[i].ssb_bonus_avg = s.bonus_avg;
          displayData[i].ssb_bonus_med = s.bonus_med;
          displayData[i].ssb_addition_avg = s.addition_avg;
          displayData[i].ssb_addition_med = s.addition_med;
        } else {
          displayData[i].salary_agreed_avg = s.salary_agreed_avg;
          displayData[i].salary_agreed_med = s.salary_agreed_med;
          displayData[i].salary_avg = s.salary_avg;
          displayData[i].salary_med = s.salary_med;
          displayData[i].bonus_avg = s.bonus_avg;
          displayData[i].bonus_med = s.bonus_med;
          displayData[i].addition_avg = s.addition_avg;
          displayData[i].addition_med = s.addition_med;
        }
      } else {
        s.non_member
          ? displayData.push({
              stat_year: s.stat_year,
              ssb_salary_agreed_avg: s.salary_agreed_avg,
              ssb_salary_agreed_med: s.salary_agreed_med,
              ssb_salary_avg: s.salary_avg,
              ssb_salary_med: s.salary_med,
              ssb_bonus_avg: s.bonus_avg,
              ssb_bonus_med: s.bonus_med,
              ssb_addition_avg: s.addition_avg,
              ssb_addition_med: s.addition_med,
            })
          : displayData.push({
              stat_year: s.stat_year,
              salary_agreed_avg: s.salary_agreed_avg,
              salary_agreed_med: s.salary_agreed_med,
              salary_avg: s.salary_avg,
              salary_med: s.salary_med,
              bonus_avg: s.bonus_avg,
              bonus_med: s.bonus_med,
              addition_avg: s.addition_avg,
              addition_med: s.addition_med,
            });
      }
    });

    // console.log("displayData", displayData)
    // console.log("stats", stats)

    setDisplayData(displayData);
    setRows(stats);
  }, [response]);

  React.useEffect(() => {
    async function updatePlotData() {
      let keys: string[] = [];

      // console.log("checkedBonus", checkedBonus)

      if (selectedValue === "avg") {
        keys.push("salary_avg", "salary_agreed_avg");
        keys.push(checkedBonus ? "bonus_avg" : "");
        keys.push(checkedAddition ? "addition_avg" : "");

        if (checkedCompare) {
          keys.push("ssb_salary_avg", "ssb_salary_agreed_avg");
          keys.push(checkedBonus ? "ssb_bonus_avg" : "");
          keys.push(checkedAddition ? "ssb_addition_avg" : "");
        }
      }
      if (selectedValue === "med") {
        keys.push("salary_med", "salary_agreed_med");
        keys.push(checkedBonus ? "bonus_med" : "");
        keys.push(checkedAddition ? "addition_med" : "");

        if (checkedCompare) {
          keys.push("ssb_salary_med", "ssb_salary_agreed_med");
          keys.push(checkedBonus ? "ssb_bonus_med" : "");
          keys.push(checkedAddition ? "ssb_addition_med" : "");
        }
      }

      keys = keys.filter((n) => n); // remove empty strings
      // console.log("keys", keys)

      // remove data from plots to trigger animation
      setSelectedKeys([]);
      await sleep(1);
      setSelectedKeys(keys);
    }

    updatePlotData();
  }, [selectedValue, rows, checkedCompare, checkedBonus, checkedAddition]);

  // React.useEffect(() => {
  //   // update to loop over all keys in selectedKeys, set min and max value to floor/ceil of 1_000
  //   if (selectedKeys.length == 0) {
  //     return;
  //   }
  //   var min: number = 100000;
  //   var max: number = 0;

  //   selectedKeys.forEach(key => {
  //     if (key.includes("bonus") || key.includes("addition")) {
  //       return;
  //     }

  //     displayData.forEach(row => {
  //       if (!checkedCompare && row.non_member) {
  //         return;
  //       }

  //       if (isKey(row, key)) {
  //         let obj: any = row[key];
  //         if (obj !== undefined) {
  //           let num: number = +obj
  //           if (num < min) {
  //             min = num;
  //           }
  //           if (num > max) {
  //             max = num;
  //           }
  //         }
  //       }
  //     });
  //   });

  //   var range: [number, number] = [Math.floor(min / 1000) * 1000, Math.ceil(max / 1000) * 1000];
  //   setDomainY(range);
  // }, [selectedKeys])

  const handleDisplayTypeClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleAvgMedChange = (event: any, clicked: string) => {
    if (selectedIndex > 1) {
      return;
    }

    if (event) {
      setSelectedValue(event.target.value);
    } else if (clicked !== "") {
      setSelectedValue(clicked);
    }
  };

  const handleCheckedCompareChange = () => {
    if (selectedIndex > 1) {
      return;
    }

    setCheckedCompare(!checkedCompare);
  };

  const handleCheckedBonusChange = () => {
    if (selectedIndex !== 1) {
      return;
    }

    setCheckedBonus(!checkedBonus);
  };

  const handleCheckedAdditionChange = () => {
    if (selectedIndex !== 1) {
      return;
    }

    setCheckedAddition(!checkedAddition);
  };

  function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
    return k in x;
  }

  return (
    <>
      <ItemCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>Se Data</h2>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: selectedIndex > 1 ? "default" : "pointer",
            }}
            onClick={() => handleAvgMedChange(null, "avg")}
          >
            <Radio
              checked={selectedValue === "avg"}
              onChange={(e) => handleAvgMedChange(e, "")}
              disabled={selectedIndex > 1}
              value="avg"
              name="radio-buttons"
              inputProps={{ "aria-label": "Gjennomsnitt" }}
            />
            <h4 style={{ margin: 0 }}>Gjennomsnitt</h4>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: selectedIndex > 1 ? "default" : "pointer",
            }}
            onClick={() => handleAvgMedChange(null, "med")}
          >
            <Radio
              checked={selectedValue === "med"}
              onChange={(e) => handleAvgMedChange(e, "")}
              disabled={selectedIndex > 1}
              value="med"
              name="radio-buttons"
              inputProps={{ "aria-label": "Median" }}
            />
            <h4 style={{ margin: 0 }}>Median</h4>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: selectedIndex > 1 ? "default" : "pointer",
              }}
              onClick={handleCheckedCompareChange}
            >
              <Checkbox
                checked={checkedCompare}
                onChange={handleCheckedCompareChange}
                disabled={selectedIndex > 1}
                inputProps={{ "aria-label": "Sammenlign" }}
              />
              <h5 style={{ margin: 0 }}>Sammenlign med ikke-medlemmer</h5>
            </Box>

            {/** @TODO get tooltip from backend */}
            <Tooltip title="Her kan du sammenlikne gjennomsnittslønnen mellom de som er og ikke er Paratmedlemmer.">
              <InfoOutlinedIcon
                sx={{
                  ml: 1,
                  color: "#0093ff",
                  fontSize: 22,
                }}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "120px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: selectedIndex !== 1 ? "default" : "pointer",
            }}
            onClick={handleCheckedBonusChange}
          >
            <Checkbox
              checked={checkedBonus}
              onChange={handleCheckedBonusChange}
              inputProps={{ "aria-label": "Bonus" }}
              disabled={selectedIndex !== 1}
            />
            <h5 style={{ margin: 0 }}>Bonus</h5>
          </Box>
          <Box
            sx={{
              width: "120px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: selectedIndex !== 1 ? "default" : "pointer",
            }}
            onClick={handleCheckedAdditionChange}
          >
            <Checkbox
              checked={checkedAddition}
              onChange={handleCheckedAdditionChange}
              inputProps={{ "aria-label": "Tillegg" }}
              disabled={selectedIndex !== 1}
            />
            <h5 style={{ margin: 0 }}>Tillegg</h5>
          </Box>
        </Box>

        <Box sx={{ mt: 1, height: 380 }}>
          <Grid container>
            <Grid item xs={3}>
              <Box sx={{ width: "90%", height: 370, overflow: "auto" }}>
                {displayOptions.map((value: any, index: number) => {
                  return (
                    <ListItem
                      key={index}
                      component="div"
                      sx={{
                        mb: 0.5,
                        backgroundColor:
                          selectedIndex === index
                            ? `${theme.palette.primary.main}`
                            : theme.palette.mode === "dark"
                            ? "black"
                            : "white",
                        color: selectedIndex === index ? "#fff" : "",
                      }}
                      disablePadding
                    >
                      <ListItemButton
                        selected={selectedIndex === index}
                        onClick={() => handleDisplayTypeClick(index)}
                      >
                        <ListItemText primary={value.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
                <Box
                  sx={{
                    mt: 3,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    gap: 1,
                  }}
                >
                  <PrivateFileDownloader
                    sx={{ width: "160px" }}
                    path="/api/static/private/2024/2/lønnstatestikk_parat.xlsx"
                    fileName="lønnstatestikk_parat_2024.xlsx"
                    buttonText="Parat tall"
                    tooltip="Last ned excel fil av alle parat tallene"
                    tooltipDir="top"
                  />
                  <PrivateFileDownloader
                    sx={{ width: "160px" }}
                    path="/api/static/private/2024/2/lønnstatestikk_ssb.xlsx"
                    fileName="lønnstatestikk_ssb_2024.xlsx"
                    buttonText="SSB tall"
                    tooltip="Last ned excel fil av alle SSB tallene"
                    tooltipDir="bottom"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                pt: 1,
                pl: 2,
                pr: 2,
                backgroundColor:
                  theme.palette.mode === "dark" ? "#10151a" : "#dfdfdf",
              }}
            >
              <h4>Avtalt månedslønn</h4>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", width: "60px" }}>Gruppe:</div>
                {selectedFilters.map((filter, index) => {
                  if (index < 3) {
                    return (
                      <div key={index}>
                        {filter.filter.display_name}
                        {index < 2 && <>&nbsp;&gt;&nbsp;</>}
                      </div>
                    );
                  }
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", width: "60px" }}>Data:</div>
                {selectedFilters.map((filter, index) => {
                  if (index > 2) {
                    return (
                      <div key={index}>
                        {filter.filter.display_name}
                        {selectedFilters.length - 1 !== index && (
                          <>&nbsp;&gt;&nbsp;</>
                        )}
                      </div>
                    );
                  }
                })}
              </Box>

              {isLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="info" />
                </Box>
              ) : error ? (
                <Alert severity="error">{error}</Alert>
              ) : response ? (
                <Box
                  sx={{
                    pt: 1,
                    width: "100%",
                    height: "300px",
                    overflow: "auto",
                  }}
                >
                  {selectedIndex === 0 && (
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={displayData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#aaa" />
                        <XAxis
                          dataKey="stat_year"
                          allowDuplicatedCategory={false}
                        />
                        <YAxis
                          tickCount={6}
                          domain={[
                            (dataMin: number) =>
                              Math.floor(dataMin / 1000) * 1000,
                            (dataMax: number) =>
                              Math.ceil((dataMax + 1) / 1000) * 1000,
                          ]}
                        />
                        <ChartTooltip
                          contentStyle={{
                            backgroundColor: `${
                              theme.palette.mode === "dark" ? "#1C2126" : "#fff"
                            }`,
                          }}
                        />
                        <Legend />

                        {displayData.length > 0 && (
                          <>
                            {selectedKeys.map((key, index) => {
                              const plotLookup = DisplayDataLookup.find(
                                (item) => item.dataKey === key
                              );
                              if (plotLookup) {
                                if (
                                  !(
                                    plotLookup.dataKey.includes("bonus") ||
                                    plotLookup.dataKey.includes("addition")
                                  )
                                ) {
                                  return (
                                    <Line
                                      key={index}
                                      data={displayData}
                                      name={plotLookup.name}
                                      type="monotone"
                                      dataKey={key}
                                      stroke={plotLookup.stroke}
                                      strokeWidth={plotLookup.strokeWidth}
                                      activeDot={{ r: plotLookup.activeDot }}
                                    />
                                  );
                                }
                              } else {
                                return (
                                  <Line
                                    key={index}
                                    data={displayData}
                                    name={key}
                                    type="monotone"
                                    dataKey={key}
                                    stroke="#000"
                                    strokeWidth={2}
                                    activeDot={{ r: 4 }}
                                  />
                                );
                              }
                            })}
                          </>
                        )}

                        {/* Support for multiple of same key name (not done) */}
                        {/* {data.length > 0 && (
                          <>
                            {selectedKeys.map((key, index) => {
                              const plotLookup: PlotLookup | undefined = DisplayDataLookup.find(item => item.dataKey === key);
                              if (key !== "stat_year") {
                                if (plotLookup) {
                                  return (
                                    <Line key={index} data={data} name={plotLookup.name} type="monotone" dataKey={key} stroke={plotLookup.stroke} strokeWidth={plotLookup.strokeWidth} activeDot={{ r: plotLookup.activeDot }} />
                                  )
                                } else {
                                  return (
                                    <Line key={index} data={data} name={key} type="monotone" dataKey={key} stroke="#000" strokeWidth={2} activeDot={{ r: 4 }} />
                                  )
                                }
                              }
                            })}
                          </>
                        )} */}
                      </LineChart>
                    </ResponsiveContainer>
                  )}

                  {selectedIndex === 1 && (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={displayData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#aaa" />
                        <XAxis dataKey="stat_year" />
                        <YAxis />
                        {/* <ChartTooltip content={CustomTooltip} /> */}
                        <ChartTooltip
                          contentStyle={{
                            backgroundColor: `${
                              theme.palette.mode === "dark" ? "#1C2126" : "#fff"
                            }`,
                          }}
                        />
                        <Legend />
                        {displayData.length > 0 && (
                          <>
                            {selectedKeys.map((key, index) => {
                              const plotLookup = DisplayDataLookup.find(
                                (item) => item.dataKey === key
                              );
                              if (plotLookup) {
                                return (
                                  <Bar
                                    key={index}
                                    name={plotLookup.name}
                                    dataKey={key}
                                    stackId={plotLookup.stackId}
                                    fill={plotLookup.stroke}
                                    strokeWidth={plotLookup.strokeWidth}
                                  />
                                );
                              } else {
                                return (
                                  <Bar
                                    key={index}
                                    name={key}
                                    dataKey={key}
                                    fill="#aaa"
                                    strokeWidth={2}
                                  />
                                );
                              }
                            })}
                          </>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  )}

                  {(selectedIndex === 2 || selectedIndex === 3) && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          minWidth: selectedIndex === 2 ? 650 : 1200,
                          overflow: "auto",
                        }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", wordBreak: "keep-all" }}
                            >
                              Type
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", wordBreak: "keep-all" }}
                              align="right"
                            >
                              År
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", wordBreak: "keep-all" }}
                              align="right"
                            >
                              Snitt lønn
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", wordBreak: "keep-all" }}
                              align="right"
                            >
                              Median lønn
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                wordBreak: "keep-all",
                                wordWrap: "revert",
                              }}
                              align="right"
                            >
                              Avtalt snitt lønn
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", wordBreak: "keep-all" }}
                              align="right"
                            >
                              Avtalt median lønn
                            </TableCell>
                            {selectedIndex === 3 && (
                              <>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    wordBreak: "keep-all",
                                  }}
                                  align="right"
                                >
                                  Snitt bonus
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    wordBreak: "keep-all",
                                  }}
                                  align="right"
                                >
                                  Median bonus
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    wordBreak: "keep-all",
                                  }}
                                  align="right"
                                >
                                  Snitt tillegg
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    wordBreak: "keep-all",
                                  }}
                                  align="right"
                                >
                                  Median tillegg
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">
                                {row.stat_year}
                              </TableCell>
                              <TableCell align="right">
                                {row.salary_avg}
                              </TableCell>
                              <TableCell align="right">
                                {row.salary_med}
                              </TableCell>
                              <TableCell align="right">
                                {row.salary_agreed_avg}
                              </TableCell>
                              <TableCell align="right">
                                {row.salary_agreed_med}
                              </TableCell>
                              {selectedIndex === 3 && (
                                <>
                                  <TableCell align="right">
                                    {row.bonus_avg}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.bonus_med}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.addition_avg}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.addition_med}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </ItemCard>
    </>
  );
};
